export enum EQuery {
  GET_COMPANIES = "GET_COMPANIES",
  GET_PUBLISHERS = "GET_PUBLISHERS",
  GET_PUBLISHERS_BY_COMPANY = "GET_PUBLISHERS_BY_COMPANY",
  GET_SINGLE_COMPANY = "GET_SINGLE_COMPANY",
  LOGIN = "LOGIN",
  ADD_ADMIN_USER = "ADD_ADMIN_USER",
  GET_COMPANY_CREDS = "GET_COMPANY_CREDS",
  GET_SINGLE_PUBLISHER = "GET_SINGLE_PUBLISHER",
  GET_BLOCKED_PLAYERS = "GET_BLOCKED_PLAYERS",
  GET_FEES = "GET_FEES",
  GET_AUDITS = "GET_AUDITS",
  GET_ROLES = "GET_ROLES",
  GET_ROUTING_RULES = "GET_ROUTING_RULES",
  GET_PAYMENT_PROVIDERS = "GET_PAYMENT_PROVIDERS",
  GET_PUBLISHER_NOTE = "GET_PUBLISHER_NOTE",
}
